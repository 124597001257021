import React from 'react';
import { Text, Box, Button, Flex, HStack, Spacer, Link } from '@chakra-ui/react';
import { Link as ReachLink } from 'react-router-dom';

export default function Preview(props) {
	const goToPreview = () => {};

	return (
		<Box bgColor="#F5E3FD">
			<HStack
				h="72px"
				bgColor={'white'}
				borderBottom="1px"
				borderBottomColor="gray.200">
				<Text
					fontSize="32px"
					pl="25px"
					pr={2}
					color="#8109B7">
					Dayseum
				</Text>
				<Text
					fontSize="20px"
					pt={2}>
					Theme Maker
				</Text>
				<Spacer />
				<Link as={ReachLink}>
					<Button
						onClick={goToPreview}
						w="128px"
						h="36px"
						bgColor="#8109B7"
						color={'white'}>
						申請する
					</Button>
				</Link>
				<Box w="25px" />
			</HStack>
			<Flex></Flex>
		</Box>
	);
}
