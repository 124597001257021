import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { BsThreeDots } from 'react-icons/bs';
export const AvatarC = () => {
	return (
		<Box
			backgroundColor={'white'}
			padding={'1rem'}
			boxShadow={'lg'}
			my={'1rem'}
			borderRadius={'10px'}>
			<Heading
				mt={'1rem'}
				fontSize="1rem"
				align="center">
				キャラクターに関するPrompt
			</Heading>
			<Box
				textAlign={'center'}
				mt={'0.5rem'}>
				<BsThreeDots
					size="1rem"
					style={{ margin: 'auto' }}></BsThreeDots>
			</Box>
		</Box>
	);
};
