import React from 'react';
import {
    Text,
    Box,
    Button,
    HStack,
    Spacer,
    Link,
} from '@chakra-ui/react';
import { Link as ReachLink, useNavigate } from "react-router-dom";


export default function Start(props) {
    const navigate = useNavigate();

    return (
        <Box bgColor='#F5E3FD'>
            <HStack h='72px' bgColor={'white'} borderBottom='1px' borderBottomColor='gray.200' >
                <Text fontSize='32px' pl='25px' pr={2} color='#8109B7'>Dayseum</Text>
                <Text fontSize='20px' pt={2}>Theme Maker</Text>
                <Spacer />
                <Link as={ReachLink} >
                    <Button onClick={navigate('/login')} w='128px' h='36px' bgColor='#8109B7' color={'white'}>
                        ログイン
                    </Button>
                </Link>
                <Box w='25px' />
            </HStack>
        </Box>
    );
}