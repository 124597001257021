import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Button, Flex, FormControl, FormLabel, Heading, Input, InputGroup, InputRightElement, VStack, Text, Spacer, HStack } from "@chakra-ui/react";
import { useNavigate, Link as ReachLink } from "react-router-dom";
import { RefreshTokenContext, TokenContext } from '../App'

export default function Login() {
    const {token, setToken} = useContext(TokenContext);
    const {refreshToken, setRefreshToken} = useContext(RefreshTokenContext);
    const [loading, setLoading] = React.useState(false);
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);

    const navigate = useNavigate();

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    const emailchange = (e) => {
        setEmail(() => e.target.value)
    }

    const passwordchange = (e) => {
        setPassword(() => e.target.value)
    }

    const enterClick = (e) => {
        if (e.key === 'Enter') {
            send_data()
        }
    }

    const send_data = async () => {
        setLoading(true);
        const data = new FormData();
        data.append("email", email);
        data.append("password", password);
        await axios.post('https://dayseum.com/api/token/', data, { withCredentials: true })
        .then(res => {
            if (res.request.status === 200) {
                window.console.log(res.data['access']);
                window.console.log(res.data['refresh']);
                setToken(res.data['access']);
                setRefreshToken(res.data['refresh']);
                console.log('login now')
                navigate('/edit');
            };
        })
        .catch(res => {
            if (res.request.status === 400) {
                alert('メールアドレスまたはパスワードに誤りがあります。');
            }
        })
        .finally(() => setLoading(false));
        
    }

    return (
        <>
            <HStack h='72px' bgColor={'white'} borderBottom='1px' borderBottomColor='gray.200' >
                <Text fontSize='32px' pl='25px' pr={2} color='#8109B7'>Dayseum</Text>
                <Text fontSize='20px' pt={2}>Theme Maker</Text>
                <Spacer/>
            </HStack>
            <Flex mt={10} justify="center" height="flex" size="full">
                <VStack spacing={6} py={4} px={10} align='stretch' w={450}>
                    <Heading as="h1" size="lg" textAlign="center">
                        ログイン
                    </Heading>
                    <FormControl isRequired>
                        <FormLabel htmlFor='email'>メールアドレス</FormLabel>
                        <Input id='email' type='email' onChange={emailchange} value={email} focusBorderColor='#8109B7'/>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel htmlFor='password'>パスワード</FormLabel>
                        <InputGroup>
                            <Input id='password' type={show ? 'text' : 'password'} onChange={passwordchange} onKeyPress={(e) => enterClick(e)} value={password} focusBorderColor='#CC5083'/>
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={handleClick} _focus={{ boxShadow: "none"}}>
                                    {/* {show ? <ViewIcon /> : <ViewOffIcon /> } */}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    <Spacer />
                    { loading ? (
                        <Button
                            bg={"#8109B7"}
                            color={'white'}
                            _hover={{ opacity: 0.8 }}
                            variant='solid'
                            onClick={send_data}
                            _focus={{ boxShadow: "none"}}
                            disabled={email === "" || password === ""}
                            isLoading
                        >ログイン</Button>
                    ) : (
                        <Button
                            bg={"#8109B7"}
                            color={'white'}
                            _hover={{ opacity: 0.8 }}
                            variant='solid'
                            onClick={send_data}
                            _focus={{ boxShadow: "none"}}
                            disabled={email === "" || password === ""}
                        >ログイン</Button>
                    )}
                </VStack>
            </Flex>
        </>
    );
};
