import {
	Badge,
	Box,
	Input,
	HStack,
	Avatar,
	Button,
	Spacer,
	FormLabel,
	Center,
	Stack,
} from '@chakra-ui/react';
import axios from 'axios';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BsThreeDots } from 'react-icons/bs';
import { elementsContext, CardsContext, TokenContext, RefreshTokenContext } from '../../App';
import React, { useContext } from 'react';
import update from 'immutability-helper';
export const PictureC = ({ index, trashFn, changeElementsFn, value, text, themeColor }) => {
	let pictureOptions = [];
	const { refreshToken, setRefreshToken } = useContext(RefreshTokenContext);
	const { elements, setElements } = useContext(elementsContext);
	const { cards, setCards } = useContext(CardsContext);
	const { token, setToken } = useContext(TokenContext);

	const handleTrash = (i) => {
		console.log('trash pic');
		trashFn(i);
	};

	const handleChangeElements = (e, i, j) => {
		console.log('change pic');
		changeElementsFn(e, i, j);
	};

	const addPictureOptions = (index) => {
		const x = Number(cards[index].num);
		elements[index].push('');
		setCards((prevCards) => update(prevCards, { [index]: { num: { $set: x + 1 } } }));
	};

	//画像を変更
	const handleFileChange = async (e, index1, index2) => {
		const file = new FormData();
		const headers = {
			Authorization: 'JWT ' + token,
			'Content-Type': 'multipart/form-data',
		};
		file.append('image', e.target.files[0]);
		window.console.log(e.target.files[0]);
		window.console.log(file);
		var s3 = '';
		if (e.target.files.length !== 0) {
			await axios
				.post('https://dayseum.com/s3convert/', file, { headers: headers })
				.then((res) => {
					if (res.request.status === 200) {
						window.console.log(res.data['url']);
						s3 = res.data['url'];
					}
					window.console.log(res.data);
					setElements((prevElements) =>
						update(prevElements, { [index1]: { [index2]: { $set: s3 } } })
					);
				})
				.catch((err) => {
					if (err.message.includes('401')) {
						const data = new FormData();
						data.append('refresh', refreshToken);
						axios.post('https://dayseum.com/api/token/refresh/', data).then((res) => {
							if (res.request.status === 200) {
								window.console.log(res.data['access']);
								window.console.log(res.data['refresh']);
								setToken(res.data['access']);
								setRefreshToken(res.data['refresh']);
								handleFileChange(index1, index2);
							}
						});
					}
				});
		}
	};

	for (let j = 0; j < (elements[index].length - 2) / 2; j++) {
		pictureOptions.push(
			<HStack
				my={'1rem'}
				key={j + 'picture' + index}>
				<label>
					<Avatar
						size="xl"
						src={elements[index][j * 2 + 1]}
						_hover={{ boxShadow: '1px 1px 5px 1px #000', transform: 'translateY(-2px)' }}
						cursor="pointer"
					/>
					<Input
						type="file"
						accept="image/*"
						style={{ display: 'none' }}
						onChange={(e) => handleFileChange(e, index, j * 2 + 1)}
					/>
				</label>
				<Box width={'100%'}>
					<FormLabel>Prompt</FormLabel>
					<Input
						onChange={(e) => handleChangeElements(e, index, j * 2 + 2)}
						value={elements[index][j * 2 + 2]}
						placeholder="prompt"
						variant="filled"
						focusBorderColor={themeColor}
					/>
				</Box>
			</HStack>
		);
	}
	return (
		<Box
			backgroundColor={'white'}
			padding={'1rem'}
			boxShadow={'lg'}
			my={'1rem'}
			borderRadius={'10px'}>
			<Box
				textAlign={'left'}
				display={'flex'}
				mb={'1rem'}>
				<Badge
					colorScheme="purple"
					height={'1rem'}>
					{text}
				</Badge>
				<Spacer></Spacer>
				<Button
					onClick={() => handleTrash(index)}
					colorScheme={'red'}
					height={'1.5rem'}>
					<RiDeleteBinLine size="1rem" />
				</Button>
			</Box>
			<Stack>
				{/* 質問欄 */}
				<FormLabel fontSize="sm">質問</FormLabel>
				<Input
					onChange={(e) => handleChangeElements(e, index, 0)}
					variant="filled"
					value={value}
					focusBorderColor={themeColor}
				/>
			</Stack>
			{pictureOptions}
			<Center my={'1rem'}>
				<Button
					onClick={() => addPictureOptions(index)}
					justifyItems="center">
					+ add options
				</Button>
			</Center>
			<Box
				textAlign={'center'}
				mt={'0.5rem'}>
				<BsThreeDots
					size="1rem"
					style={{ margin: 'auto' }}></BsThreeDots>
			</Box>
		</Box>
	);
};
