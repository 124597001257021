import React, { useEffect, useState } from 'react';
import { Badge, Box, Input, Button, Spacer, FormLabel } from '@chakra-ui/react';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BsThreeDots } from 'react-icons/bs';
const themeColor = '#8109B7';
export const TextC = ({ text, trashFn, changeElementsFn, value, index }) => {
	const handleTrash = (i) => {
		console.log('trash text');
		trashFn(i);
	};

	const handleChangeElements = (e, i) => {
		console.log('change text');
		changeElementsFn(e, i, 0);
	};
	return (
		<Box
			backgroundColor={'white'}
			padding={'1rem'}
			boxShadow={'lg'}
			my={'1rem'}
			borderRadius={'10px'}>
			<Box
				textAlign={'left'}
				display={'flex'}
				mb={'1rem'}>
				<Badge
					colorScheme="purple"
					height={'1rem'}>
					{text}
				</Badge>
				<Spacer></Spacer>
				<Button
					onClick={() => handleTrash(index)}
					colorScheme={'red'}
					height={'1.5rem'}>
					<RiDeleteBinLine size="1rem" />
				</Button>
			</Box>
			<Box>
				<FormLabel fontSize="sm">質問</FormLabel>
				<Input
					onChange={(e) => handleChangeElements(e, index)}
					variant="filled"
					value={value}
					focusBorderColor={themeColor}
				/>
			</Box>
			<Box
				textAlign={'center'}
				mt={'0.5rem'}>
				<BsThreeDots
					size="1rem"
					style={{ margin: 'auto' }}></BsThreeDots>
			</Box>
		</Box>
	);
};
