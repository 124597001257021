import update from 'immutability-helper';
import React, { useCallback, useContext, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Card } from './Card';
import { CardsContext, JsonContext, elementsContext } from '../App';

const createJsonData = (elements, cards) => {
	let json_data = { data: [] };
	for (const [i, card] of cards.entries()) {
		if (card.type === 0) {
			json_data['data'][i] = {
				type: 'constant',
				prompt: '',
			};
		} else if (card.type === 100) {
			json_data['data'][i] = {
				type: 'avatar_prompt',
			};
		} else if (card.type === 1) {
			json_data['data'][i] = {
				type: 'constant',
				prompt: elements[i][0],
			};
		} else if (card.type === 2) {
			json_data['data'][i] = {
				id: i,
				type: 'text',
				question: elements[i][0],
			};
		} else if (card.type === 3) {
			let option = [];
			for (let j = 0; j < (elements[i].length - 2) / 2; j++) {
				option.push({
					name: elements[i][j * 2 + 1],
					prompt: elements[i][j * 2 + 2],
				});
			}
			json_data['data'][i] = {
				id: i,
				type: 'radio',
				question: elements[i][0],
				option: option,
			};
		} else if (card.type === 4) {
			let option = [];
			for (let j = 0; j < (elements[i].length - 2) / 2; j++) {
				option.push({
					s3: elements[i][j * 2 + 1],
					prompt: elements[i][j * 2 + 2],
				});
			}
			json_data['data'][i] = {
				id: i,
				type: 'picture',
				question: elements[i][0],
				option: option,
			};
		}
	}
	return json_data;
};

export const Container = (props) => {
	const { jsonData, setJsonData } = useContext(JsonContext);
	const { elements, setElements } = useContext(elementsContext);
	const { cards, setCards } = useContext(CardsContext);

	//入力リストを初期化
	React.useEffect(() => {
		setJsonData(createJsonData(elements, cards));
	}, [elements, cards, setJsonData]);

	//cardの順序を入れ替える
	const moveCard = useCallback(
		(dragIndex, hoverIndex) => {
			setCards((prevCards) =>
				update(prevCards, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, prevCards[dragIndex]],
					],
				})
			);
			console.log(cards);
		},
		[cards, setCards]
	);

	//入力リストの順番を変更
	const moveElements = useCallback((dragIndex, hoverIndex) => {
		setElements((prevelements) =>
			update(prevelements, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevelements[dragIndex]],
				],
			})
		);
	});

	const renderCard = useCallback(
		(card, index) => {
			return (
				<Card
					key={'card' + index}
					index={index}
					type={card.type}
					text={card.text}
					moveCard={moveCard}
					moveElements={moveElements}
				/>
			);
		},
		[moveCard, moveElements]
	);

	return (
		<>
			<Box align="center">{cards.map((card, i) => renderCard(card, i))}</Box>
		</>
	);
};
