import { Box, Center, HStack, Circle } from '@chakra-ui/react';
import { RiPushpinLine, RiText, RiListUnordered, RiImageLine } from 'react-icons/ri';
import { BsThreeDots } from 'react-icons/bs';
import { elementsContext, CardsContext } from '../../App';
import React, { useRef, useState, useContext } from 'react';
import update from 'immutability-helper';
export const Add = ({ themeColor, index }) => {
	const { elements, setElements } = useContext(elementsContext);
	const { cards, setCards } = useContext(CardsContext);
	const clickPin = (index) => {
		setCards((prevCards) =>
			update(prevCards, {
				$splice: [
					[
						index,
						0,
						{
							type: 1,
							text: '固定Prompt',
						},
					],
				],
			})
		);
		setElements((prevElements) =>
			update(prevElements, {
				$splice: [[index, 0, ['']]],
			})
		);
	};
	const clickText = (index) => {
		setCards((prevCards) =>
			update(prevCards, {
				$splice: [
					[
						index,
						0,
						{
							type: 2,
							text: '自由入力Prompt',
						},
					],
				],
			})
		);
		setElements((prevElements) =>
			update(prevElements, {
				$splice: [[index, 0, ['']]],
			})
		);
	};

	// クリックしたら新しい「選択肢型prompt1」のカードを追加
	const clickList = (index) => {
		setCards((prevCards) =>
			update(prevCards, {
				$splice: [
					[
						index,
						0,
						{
							type: 3,
							text: '選択肢型Prompt1',
							num: 3,
						},
					],
				],
			})
		);
		setElements((prevElements) =>
			update(prevElements, {
				$splice: [[index, 0, ['', '', '', '', '', '', '']]],
			})
		);
	};

	// クリックしたら新しい「選択肢型prompt2」のカードを追加
	const clickImage = (index) => {
		setCards((prevCards) =>
			update(prevCards, {
				$splice: [
					[
						index,
						0,
						{
							type: 4,
							text: '選択肢型Prompt2',
							num: 2,
						},
					],
				],
			})
		);
		setElements((prevElements) =>
			update(prevElements, {
				$splice: [[index, 0, ['', '', '', '', '']]],
			})
		);
	};
	return (
		<Box
			backgroundColor={'white'}
			padding={'1rem'}
			boxShadow={'lg'}
			my={'1rem'}
			borderRadius={'10px'}>
			<Center>
				<HStack
					pt="16px"
					px={4}
					spacing="30px">
					<Circle
						size="3rem"
						bg="white"
						cursor={'pointer'}
						color={themeColor}
						border="1px"
						borderColor={themeColor}
						onClick={() => clickPin(index)}>
						<RiPushpinLine size="1rem" />
					</Circle>
					<Circle
						size="3rem"
						bg="white"
						cursor={'pointer'}
						color={themeColor}
						border="1px"
						borderColor={themeColor}
						onClick={() => clickText(index)}>
						<RiText size="1rem" />
					</Circle>
					<Circle
						size="3rem"
						bg="white"
						cursor={'pointer'}
						color={themeColor}
						border="1px"
						borderColor={themeColor}
						onClick={() => clickList(index)}>
						<RiListUnordered size="1rem" />
					</Circle>
					<Circle
						size="3rem"
						bg="white"
						cursor={'pointer'}
						color={themeColor}
						border="1px"
						borderColor={themeColor}
						onClick={() => clickImage(index)}>
						<RiImageLine size="1rem" />
					</Circle>
				</HStack>
			</Center>
			<Box
				textAlign={'center'}
				mt={'0.5rem'}>
				<BsThreeDots
					size="1rem"
					style={{ margin: 'auto' }}></BsThreeDots>
			</Box>
		</Box>
	);
};
