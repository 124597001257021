import {
	Badge,
	Box,
	Input,
	Button,
	Spacer,
	FormLabel,
	Center,
	HStack,
	Checkbox,
} from '@chakra-ui/react';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BsThreeDots } from 'react-icons/bs';
import { elementsContext, CardsContext } from '../../App';
import React, { useContext } from 'react';
import update from 'immutability-helper';
export const ListC = ({ index, trashFn, changeElementsFn, value, text, themeColor }) => {
	let radioOptions = [];
	const { elements, setElements } = useContext(elementsContext);
	const { cards, setCards } = useContext(CardsContext);

	const addRadioOptions = (index) => {
		console.log('add Options');
		const x = Number(cards[index].num);
		console.log(x);
		elements[index].push('', '');
		setCards((prevCards) => update(prevCards, { [index]: { num: { $set: x + 1 } } }));
	};

	const handleTrash = (i) => {
		console.log('trash List');
		trashFn(i);
	};

	const handleChangeElements = (e, i, j) => {
		console.log('change List');
		changeElementsFn(e, i, j);
	};
	for (let j = 0; j < (elements[index].length - 2) / 2; j++) {
		radioOptions.push(
			<HStack
				key={j + 'radio' + index}
				justifyContent={'space-between'}
				my={'1rem'}>
				<Checkbox
					isReadOnly={true}
					cursor={'move'}
					width={'5%'}
				/>
				<Box width={'45%'}>
					<FormLabel>表示名</FormLabel>
					<Input
						onChange={(e) => handleChangeElements(e, index, j * 2 + 1)}
						value={elements[index][j * 2 + 1]}
						variant="filled"
						focusBorderColor={themeColor}
					/>
				</Box>
				<Box width={'45%'}>
					<FormLabel>Prompt</FormLabel>
					<Input
						onChange={(e) => handleChangeElements(e, index, j * 2 + 2)}
						value={elements[index][j * 2 + 2]}
						variant="filled"
						focusBorderColor={themeColor}
					/>
				</Box>
			</HStack>
		);
	}
	return (
		<Box
			backgroundColor={'white'}
			padding={'1rem'}
			boxShadow={'lg'}
			my={'1rem'}
			borderRadius={'10px'}>
			<Box
				textAlign={'left'}
				display={'flex'}
				mb={'1rem'}>
				<Badge
					colorScheme="purple"
					height={'1rem'}>
					{text}
				</Badge>
				<Spacer></Spacer>
				<Button
					onClick={() => handleTrash(index)}
					colorScheme={'red'}
					height={'1.5rem'}>
					<RiDeleteBinLine size="1rem" />
				</Button>
			</Box>
			<Box>
				<FormLabel fontSize="sm">質問</FormLabel>
				<Input
					onChange={(e) => handleChangeElements(e, index, 0)}
					variant="filled"
					value={value}
					focusBorderColor={themeColor}
				/>
			</Box>
			{radioOptions}
			<Box mt={'1rem'}>
				<Center mt={'1rem'}>
					<Button
						onClick={() => addRadioOptions(index)}
						justifyItems="center">
						+ add options
					</Button>
				</Center>
			</Box>
			<Box
				textAlign={'center'}
				mt={'0.5rem'}>
				<BsThreeDots
					size="1rem"
					style={{ margin: 'auto' }}></BsThreeDots>
			</Box>
		</Box>
	);
};
