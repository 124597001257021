import React, { useState, useEffect, useContext } from 'react';
import {
	Text,
	Box,
	Button,
	Flex,
	HStack,
	Input,
	Select,
	Spacer,
	Textarea,
	Avatar,
	SimpleGrid,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
} from '@chakra-ui/react';
import { RiImageLine } from 'react-icons/ri';
import { Container } from '../components/Container';
import axios from 'axios';
import { JsonContext, RefreshTokenContext, elementsContext, TokenContext } from '../App';
import { json } from 'react-router-dom';

export default function Top(props) {
	const { token, setToken } = useContext(TokenContext);
	const { refreshToken, setRefreshToken } = useContext(RefreshTokenContext);
	const { jsonData, setJsonData } = useContext(JsonContext);
	const { elements, setElements } = useContext(elementsContext);
	const [title, setTitle] = useState('theme');
	const [model, setModel] = useState('Dayseum Diffusion');
	const [scale, setScale] = useState('12');
	const [step, setStep] = useState('28');
	const [gender, setGender] = useState('all');
	//1024まで
	const [height, setHeight] = useState('640');
	//1024まで
	const [width, setWidth] = useState('640');
	const [sampling, setSampling] = useState('DDIM');
	const [seed, setSeed] = useState('');
	const [useAvatar, setUseAvatar] = useState(true);
	const [negative, setNegative] = useState(
		'bad quality, worst quality, low quality, frame, text, woman, 2girls, bad anatomy, breast, long hair, bob cut, bad hands, logo, signature, lowres'
	);
	const [isEightW, setIsEightW] = useState(false);
	const [isEightHeight, setIsEightHeight] = useState(false);
	const [src, setSrc] = useState('');
	const themeColor = '#8109B7';

	useEffect(() => {
		var interval;
		const load_post = async (themeId) => {
			const headers = { Authorization: 'JWT ' + token };
			window.console.log(headers);
			const data = new FormData();
			data.append('theme_id', themeId);
			await axios
				.post('https://dayseum.com/theme/get/', data, { headers: headers })
				.then((res) => {
					if (res.request.status === 200) {
						window.console.log(res.data);
						setTitle();
						setModel();
						setScale();
						setStep();
						setGender();
						setHeight();
						setWidth();
						setSampling();
						setSeed();
						setUseAvatar();
						setNegative();
						setIsEightW(true);
						setIsEightHeight(true);
					}
				})
				.finally(() => {});
		};

		//samplingをAPIで取得
		const loadSampling = async () => {
			const headers = {
				Authorization: 'JWT ' + token,
			};
			await axios
				.get('https://dayseum.com/sampling/', { headers: headers })
				.then((res) => {
					if (res.request.status === 200) {
						window.console.log(res.data);
					}
				})
				.finally(() => {});
		};
		// load_post();
		// loadSampling();
		return () => {
			clearInterval(interval);
		};
	}, [props, token]);

	const change_title = (e) => setTitle(e.target.value);
	const change_model = (e) => setModel(e.target.value);
	const change_scale = (e) => setScale(e.target.value);
	const change_step = (e) => setStep(e.target.value);
	const change_gender = (e) => setGender(e.target.value);
	const change_height = (e) => {
		const value = e.target.value;
		if (value % 8 === 0) {
			setIsEightHeight(true);
			setHeight(e.target.value);
		} else if (value % 8 > 0) {
			setIsEightHeight(false);
			setHeight(e.target.value);
			console.log(height);
		}
	};

	const change_width = (e) => {
		const value = e.target.value;
		if (value % 8 === 0) {
			setIsEightW(true);
			setWidth(e.target.value);
		} else if (value % 8 > 0) {
			// set = Number(value) + Number(8 - (value % 8));
			setIsEightW(false);
			setWidth(e.target.value);
			console.log(height);
		}
	};
	const change_sampling = (e) => setSampling(e.target.value);
	const change_seed = (e) => setSeed(e.target.value);
	const change_useAvatar = (e) => {
		setUseAvatar(e.target.value);
	};
	const change_negative = (e) => setNegative(e.target.value);
	const srcChange = (src) => {
		// window.console.log(token);
		return async function (e) {
			const file = new FormData();
			const headers = {
				Authorization: 'JWT ' + token,
				'Content-Type': 'multipart/form-data',
			};
			file.append('image', e.target.files[0]);
			window.console.log(e.target.files[0]);
			window.console.log(headers);
			let s3 = '';
			if (e.target.files.length !== 0) {
				await axios
					.post('https://dayseum.com/s3convert/', file, { headers: headers })
					.then((res) => {
						if (res.request.status === 200) {
							window.console.log(res.data['url']);
							s3 = res.data['url'];
							setSrc(s3);
						}
					})
					.catch((err) => {
						window.console.log(err.message.includes('401'));
						if (err.message.includes('401')) {
							window.console.log(refreshToken);
							const data = new FormData();
							data.append('refresh', refreshToken);
							axios.post('https://dayseum.com/api/token/refresh/', data).then((res) => {
								if (res.request.status === 200) {
									setToken(res.data['access']);
									setRefreshToken(res.data['refresh']);
									srcChange(src);
								}
							});
						}
					});
			}
		};
	};

	const sendData = async () => {
		const data = new FormData();
		data.append('json', JSON.stringify(jsonData));
		data.append('name', title);
		data.append('width', width);
		data.append('height', height);
		data.append('scales', scale);
		data.append('step', step);
		data.append('sampling', 'EulerAncestralDiscrete');
		data.append('s3', src);
		data.append('n_prompt', negative);
		data.append('category', gender);
		const headers = {
			Authorization: 'JWT ' + token,
		};
		window.console.log(headers);
		window.console.log(data);
		await axios
			.post('https://dayseum.com/theme/post/', data, { headers: headers })
			.then((res) => {
				if (res.request.status === 200) {
					window.console.log(res.data);
					alert('Complete');
				}
				window.console.log(res.data);
			})
			.catch((err) => {
				window.console.log(refreshToken);
				if (err.message.includes('401')) {
					window.console.log(refreshToken);
					const data = new FormData();
					data.append('refresh', refreshToken);
					axios.post('https://dayseum.com/api/token/refresh/', data).then((res) => {
						if (res.request.status === 200) {
							window.console.log('大成功');
							window.console.log(res.data);
							setToken(res.data['access']);
							setRefreshToken(res.data['refresh']);
						}
					});
				}
			});
	};

	const console = () => {
		window.console.log(elements);
		window.console.log(refreshToken);
		alert(JSON.stringify(jsonData));
	};

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isDOpen, onOpen: onDOpen, onClose: onDClose } = useDisclosure();
	const btnRef = React.useRef();
	return (
		<Box bgColor="#FDF9FF">
			<Flex
				position={'fixed'}
				top={'0'}
				backgroundColor={'#FDF9FF'}
				zIndex={'999'}
				width={'100%'}
				height={'auto'}
				padding={'2rem 1rem'}>
				<Text
					fontSize="32px"
					pl="25px"
					pr={2}
					color={themeColor}>
					Dayseum
				</Text>
				<Text
					fontSize="20px"
					pt={2}>
					Theme Maker
				</Text>
				<Spacer></Spacer>
				<Button
					onClick={console}
					bgColor={themeColor}
					color={'white'}>
					コンソールで確認
				</Button>
				<Button
					marginLeft={'1rem'}
					onClick={sendData}
					bgColor={themeColor}
					color={'white'}>
					保存
				</Button>
			</Flex>
			<Box
				display={{ base: 'block', md: 'flex' }}
				mt={'112px'}>
				{/* 左サイドバー */}
				<Box
					padding={'1rem'}
					position={{ md: 'sticky', base: 'relative' }}
					top={{ md: '112px', base: '0' }}
					width={{ md: '400px', base: '100%' }}
					height={'100%'}>
					<Box
						bgColor={'white'}
						padding={'2rem'}
						borderRadius={'10px'}
						boxShadow={'md'}>
						<Button
							width={'100%'}
							onClick={onOpen}>
							設定
						</Button>
						<Button
							mt={'1rem'}
							ref={btnRef}
							width={'100%'}
							colorScheme="teal"
							onClick={onDOpen}>
							テンプレート
						</Button>
					</Box>
				</Box>
				{/* 真ん中 */}
				<Box flexGrow={'1'}>
					<Box
						width={{ md: '90%', base: '100%' }}
						margin={'auto'}>
						<Container defaultelements={elements} />
					</Box>
				</Box>
				<Box
					bg={'white'}
					padding={'1rem'}
					maxWidth={'30%'}
					overflowX={'auto'}>
					<Box>
						<pre>{JSON.stringify(jsonData, null, '\t')}</pre>
					</Box>
				</Box>
			</Box>
			<Modal
				isOpen={isOpen}
				onClose={onClose}>
				<ModalOverlay></ModalOverlay>
				<ModalContent>
					<ModalHeader>設定</ModalHeader>
					<ModalCloseButton></ModalCloseButton>
					<ModalBody>
						{/* カバー画像 */}
						<Box mt={'2rem'} align={'center'}>
							<label>
								<Avatar
									style={{ textAlign: 'center', width: '30%', height: '30%' }}
									size="xl"
									src={src}
									cursor="pointer"
								/>
								<Input
									type="file"
									accept="image/*"
									style={{ display: 'none' }}
									onChange={srcChange(src)}
								/>
							</label>
							<Text
								fontSize="0.8rem"
								textAlign={'center'}>
								カバー画像を変更
							</Text>
						</Box>
						{/* テーマ名 */}
						<Box my={'1rem'}>
							<Text fontSize="0.8rem">テーマ名</Text>
							<Input
								defaultValue={title}
								onChange={change_title}
								variant={'filled'}
							/>
						</Box>
						<Box my={'1rem'}>
							<Text fontSize="0.8rem">モデル</Text>
							<Select
								defaultValue={model}
								onChange={change_model}
								variant={'filled'}>
								<option value="DD1.0">Dayseum Diffusion v1.0</option>
							</Select>
						</Box>
						{/* Scale */}
						<Box my={'1rem'}>
							<Text fontSize="0.8rem">Scale</Text>
							<Input
								defaultValue={scale}
								onChange={change_scale}
								variant={'filled'}
							/>
						</Box>
						{/* Step */}
						<Box my={'1rem'}>
							<Text fontSize="0.8rem">Step</Text>
							<Input
								defaultValue={step}
								onChange={change_step}
								variant={'filled'}
							/>
						</Box>
						{/* 対象 */}
						<Box my={'1rem'}>
							<Text fontSize="0.8rem">対象</Text>
							<Select
								defaultValue={gender}
								onChange={change_gender}
								variant={'filled'}>
								<option value="all">all</option>
								<option value="male">male</option>
								<option value="female">female</option>
							</Select>
						</Box>
						{/* サイズ */}
						<Box my={'1rem'}>
							<Text fontSize="0.8rem">サイズ(Width×height)</Text>
							<HStack>
								{/* width */}
								<Input
									defaultValue={width}
									onChange={change_width}
									type="number"
									variant={'filled'}
								/>
								<Text fontSize="1rem">&times;</Text>
								{/* height */}
								<Input
									defaultValue={height}
									onChange={change_height}
									type="number"
									variant={'filled'}
								/>
							</HStack>
							{!isEightW || !isEightHeight ? (
								<Text
									fontSize="0.8rem"
									color="red">
									※pxは8の倍数で指定してください
								</Text>
							) : (
								''
							)}
						</Box>
						<Box my={'1rem'}>
							<Text fontSize="0.8rem">Sampling</Text>
							<Select
								defaultValue={sampling}
								onChange={change_sampling}
								variant={'filled'}>
								<option value="ddim">DDIM</option>
							</Select>
						</Box>
						<Box my={'1rem'}>
							<Text fontSize="0.8rem">Seed(Optional)</Text>
							<Input
								defaultValue={seed}
								onChange={change_seed}
								variant={'filled'}
							/>
						</Box>
						<Box my={'1rem'}>
							<Text fontSize="0.8rem">キャラクターの使用</Text>
							<Select
								defaultValue={useAvatar}
								onChange={change_useAvatar}
								variant={'filled'}>
								<option value="true">キャラクターを使用する</option>
								<option value="false">キャラクターを使用しない</option>
							</Select>
						</Box>
						{/* Negative Prompt */}
						<Box my={'1rem'}>
							<Text fontSize="0.8rem">Negative Prompt</Text>
							<Textarea
								defaultValue={
									'bad quality, worst quality, low quality, frame, text, woman, 2girls, bad anatomy, breast, long hair, bob cut, bad hands, logo, signature, lowres'
								}
								value={negative}
								size={'xs'}
								variant={'filled'}
								onChange={change_negative}
							/>
						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
			<Drawer
				isOpen={isDOpen}
				placement="left"
				onClose={onDClose}>
				<DrawerOverlay></DrawerOverlay>
				<DrawerContent>
					<DrawerCloseButton></DrawerCloseButton>
					<DrawerHeader>テンプレート</DrawerHeader>
					<DrawerBody>
						<SimpleGrid
							columns={1}
							spacing={'1rem'}>
							{/* Color */}
							<Box
								display={'flex'}
								bgColor={'white'}
								padding={'1rem'}
								boxShadow={'lg'}
								borderRadius={'10px'}
								alignItems={'center'}>
								<RiImageLine size={'2rem'} />
								<Text ml={'1rem'}>Color</Text>
							</Box>
							{/* Hair Ornaments */}
							<Box
								display={'flex'}
								bgColor={'white'}
								padding={'1rem'}
								boxShadow={'lg'}
								borderRadius={'10px'}
								alignItems={'center'}>
								<RiImageLine size={'2rem'} />
								<Text ml={'1rem'}>Hair Ornaments</Text>
							</Box>

							{/* Face Ornaments */}
							<Box
								display={'flex'}
								bgColor={'white'}
								padding={'1rem'}
								boxShadow={'lg'}
								borderRadius={'10px'}
								alignItems={'center'}>
								<RiImageLine size={'2rem'} />
								<Text ml={'1rem'}>Face Ornaments</Text>
							</Box>

							{/* Angle of View */}
							<Box
								display={'flex'}
								bgColor={'white'}
								padding={'1rem'}
								boxShadow={'lg'}
								borderRadius={'10px'}
								alignItems={'center'}>
								<RiImageLine size={'2rem'} />
								<Text ml={'1rem'}>Angle of View</Text>
							</Box>

							{/* Aspect of Face */}
							<Box
								display={'flex'}
								bgColor={'white'}
								padding={'1rem'}
								boxShadow={'lg'}
								borderRadius={'10px'}
								alignItems={'center'}>
								<RiImageLine size={'2rem'} />
								<Text ml={'1rem'}>Aspect of Face</Text>
							</Box>

							{/* Expressions */}
							<Box
								display={'flex'}
								bgColor={'white'}
								padding={'1rem'}
								boxShadow={'lg'}
								borderRadius={'10px'}
								alignItems={'center'}>
								<RiImageLine size={'2rem'} />
								<Text ml={'1rem'}>Expressions</Text>
							</Box>

							{/* Pose */}
							<Box
								display={'flex'}
								bgColor={'white'}
								padding={'1rem'}
								boxShadow={'lg'}
								borderRadius={'10px'}
								alignItems={'center'}>
								<RiImageLine size={'2rem'} />
								<Text ml={'1rem'}>Pose</Text>
							</Box>

							{/* Hair Status */}
							<Box
								display={'flex'}
								bgColor={'white'}
								padding={'1rem'}
								boxShadow={'lg'}
								borderRadius={'10px'}
								alignItems={'center'}>
								<RiImageLine size={'2rem'} />
								<Text ml={'1rem'}>Hair Status</Text>
							</Box>

							{/* Costume */}
							<Box
								display={'flex'}
								bgColor={'white'}
								padding={'1rem'}
								boxShadow={'lg'}
								borderRadius={'10px'}
								alignItems={'center'}>
								<RiImageLine size={'2rem'} />
								<Text ml={'1rem'}>Costume</Text>
							</Box>

							{/* Hat Costume */}
							<Box
								display={'flex'}
								bgColor={'white'}
								padding={'1rem'}
								boxShadow={'lg'}
								borderRadius={'10px'}
								alignItems={'center'}>
								<RiImageLine size={'2rem'} />
								<Text ml={'1rem'}>Hat Costume</Text>
							</Box>

							{/* Picture Style */}
							<Box
								display={'flex'}
								bgColor={'white'}
								padding={'1rem'}
								boxShadow={'lg'}
								borderRadius={'10px'}
								alignItems={'center'}>
								<RiImageLine size={'2rem'} />
								<Text ml={'1rem'}>Picture Style</Text>
							</Box>
						</SimpleGrid>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</Box>
	);
}
