import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Button, Flex, FormControl, FormLabel, Heading, Input, InputGroup, InputRightElement, Link, VStack, Text, Spacer, HStack, Box } from "@chakra-ui/react";
import { useNavigate, Link as ReachLink } from "react-router-dom";
import { TokenContext } from '../App'
// import useContext from '../components/UseToken';

// const endpoint = "https://nextswan.com";
export default function Menu() {
    const { token, setToken } = useContext(TokenContext);
    const [loading, setLoading] = React.useState(false);
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);

    const navigate = useNavigate();

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [next, setNext] = React.useState('');


    React.useEffect(() => {
        const url = new URL(window.location.href);
        const params = url.searchParams;
        setNext(params.get('next'));
        // console.log(params.get('next'));
    }, []);

    const emailchange = (e) => {
        setEmail(() => e.target.value)
    }

    const passwordchange = (e) => {
        setPassword(() => e.target.value)
    }

    const enterClick = (e) => {
        if (e.key === 'Enter') {
            send_data()
        }
    }

    const to_signup = () => {
        if (next) navigate('/signup?next=' + next);
        else navigate('/signup');
    }
    const forget = () => {
        navigate('/settings/password/reset');
    }

    const send_data = async () => {
        setLoading(true);
        const data = new FormData();
        data.append("email", email);
        data.append("password", password);
        await axios.post('https://dayseum.com/api/token/', data, { withCredentials: true })
            .then(res => {
                if (res.request.status === 200) {
                    console.log(res.request.status);
                    setToken(res.data['access']);
                    console.log('login now')
                    console.log(token)
                    //         if (next==='signup') navigate('/');
                    //         else if (next) navigate('/' + next + '/');
                    navigate('/');
                };
                console.log(res.request.status);
                console.log(res.data);
            })
            .catch(res => {
                if (res.request.status === 400) {
                    alert('メールアドレスまたはパスワードに誤りがあります。');
                }
            })
            .finally(() => setLoading(false));
    }

    return (
        <>
            <HStack h='72px' bgColor={'white'} borderBottom='1px' borderBottomColor='gray.200' >
                <Text fontSize='32px' pl='25px' pr={2} color='#8109B7'>Dayseum</Text>
                <Text fontSize='20px' pt={2}>Theme Maker</Text>
                <Spacer />
                <Link as={ReachLink} to='/preview' >
                    <Button w='128px' h='36px' bgColor='#8109B7' color={'white'}>続ける</Button>
                </Link>
                <Box w='25px' />
            </HStack>
            <Flex mt={10} justify="center" height="flex" size="full">
                <VStack spacing={6} py={4} px={10} align='stretch' w={450}>
                    <Heading as="h1" size="lg" textAlign="center">
                        Theme ID
                    </Heading>
                    <FormControl isRequired>
                        <FormLabel htmlFor='email'>Theme ID</FormLabel>
                        <Input onChange={emailchange} value={email} focusBorderColor='#8109B7' />
                    </FormControl>
                    <Spacer />
                    {loading ? (
                        <Button
                            bg={"#8109B7"}
                            color={'white'}
                            _hover={{ opacity: 0.8 }}
                            // loadingText='ログイン'
                            variant='solid'
                            onClick={send_data}
                            _focus={{ boxShadow: "none" }}
                            disabled={email === "" || password === ""}
                            isLoading
                        >編集する</Button>
                    ) : (
                        <Button
                            bg={"#8109B7"}
                            color={'white'}
                            _hover={{ opacity: 0.8 }}
                            // loadingText='ログイン'
                            variant='solid'
                            onClick={send_data}
                            _focus={{ boxShadow: "none" }}
                            disabled={email === "" || password === ""}
                        >編集する</Button>
                    )}
                </VStack>
            </Flex>
        </>
    );
};
