import React, { useEffect, useState } from 'react';
import { Badge, Box, Input, Button, Spacer } from '@chakra-ui/react';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BsThreeDots } from 'react-icons/bs';
export const Constant = ({ changeElementsFn, text, trashFn, index, value }) => {
	const handleTrash = (i) => {
		console.log('trash constant');
		trashFn(i);
	};

	const handleChangeElements = (e, i) => {
		console.log('change constant');
		changeElementsFn(e, i, 0);
	};

	return (
		<Box
			backgroundColor={'white'}
			padding={'1rem'}
			boxShadow={'lg'}
			my={'1rem'}
			borderRadius={'10px'}>
			<Box
				textAlign={'left'}
				display={'flex'}
				mb={'1rem'}>
				<Badge
					colorScheme="purple"
					height={'1rem'}>
					{text}
				</Badge>
				<Spacer></Spacer>
				<Button
					onClick={() => handleTrash(index)}
					colorScheme={'red'}
					height={'1.5rem'}>
					<RiDeleteBinLine size="1rem" />
				</Button>
			</Box>
			<Input
				onChange={(e) => handleChangeElements(e, index)}
				variant={'filled'}
				value={value}
			/>
			<Box
				textAlign={'center'}
				mt={'0.5rem'}>
				<BsThreeDots
					size="1rem"
					style={{ margin: 'auto' }}></BsThreeDots>
			</Box>
		</Box>
	);
};
