import React, { useState, createContext, useContext } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: `"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "Meiryo", "MS Pgothic", knowledge-reg, helvetica,arial, sans-serif;`,
    body: `"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "Meiryo", "MS Pgothic", knowledge-reg, helvetica,arial, sans-serif;`
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div position="relative">
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ChakraProvider>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();