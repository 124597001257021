import React, { useState, createContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import { Box } from '@chakra-ui/react';
import Top from './pages/Top';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Preview from './pages/Preview';
import Login from './pages/Login';
import Menu from './pages/Menu';
import Start from './pages/Start';

export const TokenContext = createContext({ value: '' });
export const RefreshTokenContext = createContext({ value: '' });
export const JsonContext = createContext({ value: {} });
export const elementsContext = createContext({
	value: [],
});
export const CardsContext = createContext({
	value: [],
});
function App() {
	const [token, setToken] = useState(localStorage.getItem('token') || '');
	const [refreshToken, setRefreshToken] = useState(localStorage.getItem('token') || '');
	const [jsonData, setJsonData] = useState(localStorage.getItem('json') || {});
	const [elements, setElements] = useState(
		localStorage.getItem('elements') || [
			[''],
			[''],
			[''],
			[''],
			[''],
			['', '', '', '', '', '', ''],
			['', '', '', '', '', '', '', '', ''],
		]
	);
	const [cards, setCards] = useState(
		localStorage.getItem('cards') || [
			{
				type: 1,
				text: '固定Prompt',
			},
			{
				type: 1,
				text: '固定Prompt',
			},
			{
				type: 0,
				text: '',
			},
			{
				type: 100,
				text: '',
			},
			{
				type: 2,
				text: '自由入力Prompt',
			},
			{
				type: 3,
				text: '選択肢型Prompt1',
				num: 3,
			},
			{
				type: 4,
				text: '選択肢型Prompt2',
				num: 4,
			},
		]
	);
	const value = {
		token,
		setToken,
	};
	const refreshValue = {
		refreshToken,
		setRefreshToken,
	};
	const jsonValue = {
		jsonData,
		setJsonData,
	};
	const elementsValue = {
		elements,
		setElements,
	};

	const cardsValue = {
		cards,
		setCards,
	};

	useEffect(() => {
		localStorage.setItem('token', token);
	}, [token]);

	return (
		<Box minH="100vh">
			<DndProvider backend={HTML5Backend}>
				<TokenContext.Provider value={value}>
					<RefreshTokenContext.Provider value={refreshValue}>
						<JsonContext.Provider value={jsonValue}>
							<elementsContext.Provider value={elementsValue}>
								<CardsContext.Provider value={cardsValue}>
									<Routes>
										<Route
											path="/"
											element={<Start />}
										/>
										<Route
											path="/edit"
											element={<Top />}
										/>
										<Route
											path="/preview"
											element={<Preview />}
										/>
										<Route
											path="/login"
											element={<Login />}
										/>
										<Route
											path="/menu"
											element={<Menu />}
										/>
									</Routes>
								</CardsContext.Provider>
							</elementsContext.Provider>
						</JsonContext.Provider>
					</RefreshTokenContext.Provider>
				</TokenContext.Provider>
			</DndProvider>
		</Box>
	);
}

export default App;
