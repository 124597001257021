import React, { useRef, useState, useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';
import { elementsContext, CardsContext } from '../App';
import { Constant } from './Cards/Constant';
import { AvatarC } from './Cards/AvatarC';
import { TextC } from './Cards/TextC';
import { Add } from './Cards/Add';
import { ListC } from './Cards/ListC';
import { PictureC } from './Cards/PictureC';

const style = {
	cursor: 'move',
};

const themeColor = '#8109B7';

const ItemTypes = {
	FIXED: 'fixed',
	FREE: 'free',
	SELECT: 'selecct',
	IMAGE: 'image',
};

export const Card = ({ id, type, text, index, moveCard, moveElements }) => {
	const { elements, setElements } = useContext(elementsContext);
	const { cards, setCards } = useContext(CardsContext);
	const [isHover, setIsHover] = useState(false);
	const ref = useRef(null);

	// カードを削除
	const trash = (index) => {
		setCards((prevCards) =>
			update(prevCards, {
				$splice: [[index, 1]],
			})
		);
		setElements((prevElements) =>
			update(prevElements, {
				$splice: [[index, 1]],
			})
		);
		console.log(elements);
	};

	//入力リストを変更
	const changeElements = (e, index1, index2) => {
		setElements((prevElements) =>
			update(prevElements, { [index1]: { [index2]: { $set: e.target.value } } })
		);
	};

	const [{ handlerId }, drop] = useDrop({
		accept: ItemTypes.FIXED,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;
			// 自分自身に置き換えない
			if (dragIndex === hoverIndex) {
				return;
			}
			// 画面上の四角形を決定する
			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			// 垂直方向の真ん中を取得
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			// マウス位置の決定
			const clientOffset = monitor.getClientOffset();
			// ピクセル単位で取得
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;
			// マウスがアイテムの高さの半分を超えたときのみ移動を実行する
			// 下方向にドラッグした場合、カーソルが50%以下のときだけ移動する
			// 上方向にドラッグした場合、カーソルが50%より上にあるときのみ移動する
			// 下方向へのドラッグ
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}
			// 上方向へのドラッグ
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}
			// 実際にアクションを起こす
			moveCard(dragIndex, hoverIndex);
			moveElements(dragIndex, hoverIndex);
			// 注意: ここではモニタの項目を変異させています!
			// 一般的には、変異は避けるのがよいのですが、ここではパフォーマンスのために、
			// 余計なインデックス検索を避けるために変異させるのがよいでしょう。
			item.index = hoverIndex;
		},
	});

	const [{ isDragging }, drag] = useDrag({
		type: ItemTypes.FIXED,
		item: () => {
			return { id, index };
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const opacity = 1;
	drag(drop(ref));

	//card追加カード
	if (type === 0 && typeof elements[index] !== 'undefined') {
		return (
			<div
				ref={ref}
				style={{ ...style, opacity }}
				data-handler-id={handlerId}>
				<Add index={index}></Add>
			</div>
		);
	}
	//キャラクターprompt(編集不可)
	if (type === 100 && typeof elements[index] !== 'undefined') {
		return (
			<div
				ref={ref}
				style={{ ...style, opacity }}
				data-handler-id={handlerId}>
				<AvatarC></AvatarC>
			</div>
		);
	}
	//固定P
	if (type === 1 && typeof elements[index] !== 'undefined') {
		return (
			<div
				ref={ref}
				style={{ ...style, opacity }}
				data-handler-id={handlerId}>
				<Constant
					changeElementsFn={changeElements}
					text={text}
					trashFn={trash}
					index={index}
					value={elements[index][0]}></Constant>
			</div>
		);
	}
	//自由入力P
	if (type === 2 && typeof elements[index] !== 'undefined') {
		return (
			<div
				ref={ref}
				style={{ ...style, opacity }}
				data-handler-id={handlerId}>
				<TextC
					text={text}
					trashFn={trash}
					index={index}
					changeElementsFn={changeElements}
					value={elements[index][0]}></TextC>
			</div>
		);
	}
	//選択可能1(リスト)
	if (type === 3) {
		if (typeof elements[index] !== 'undefined') {
			return (
				<div
					ref={ref}
					style={{ ...style, opacity }}
					data-handler-id={handlerId}>
					<ListC
						index={index}
						trashFn={trash}
						changeElementsFn={changeElements}
						value={elements[index][0]}
						text={text}
						themeColor={themeColor}></ListC>
				</div>
			);
		}
	}
	//選択可能2(image)
	if (type === 4) {
		if (typeof elements[index] !== 'undefined') {
			return (
				<div
					ref={ref}
					style={{ ...style, opacity }}
					data-handler-id={handlerId}>
					<PictureC
						index={index}
						trashFn={trash}
						changeElementsFn={changeElements}
						value={elements[index][0]}
						text={text}
						themeColor={themeColor}></PictureC>
				</div>
			);
		}
	}
};
